var l55 = {
  code: 'nn',
  week: {
    dow: 1,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  buttonText: {
    prev: 'Førre',
    next: 'Neste',
    today: 'I dag',
    year: 'År',
    month: 'Månad',
    week: 'Veke',
    day: 'Dag',
    list: 'Agenda'
  },
  weekText: 'Veke',
  allDayText: 'Heile dagen',
  moreLinkText: 'til',
  noEventsText: 'Ingen hendelser å vise'
};
export { l55 as default };